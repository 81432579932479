// @flow

import type { RichTextField } from '../types';

export default function hasRichTextFieldValue(richTextField?: ?RichTextField) {
  return !!(
    richTextField &&
    richTextField.text !== '' &&
    typeof richTextField.html === 'string' &&
    richTextField.html !== '<p></p>'
  );
}
