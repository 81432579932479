// @flow
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import CookieConsent from '../plugins/cookie-consent/components/CookieConsent';
import AppIntlProvider from '../plugins/i18n/components/AppIntlProvider';
import AppHelmet from '../plugins/helmet/components/AppHelmet';
import PageContext, { type Page$Context } from '../plugins/page-context';
import BaseLayout from './BaseLayout';
import '../../config/material-ui/fonts/fonts.css';

export type Props = {
  children: React.Node,
  pageContext: Page$Context,
};

const Layout = ({ children, ...props }: Props) => (
  <AppIntlProvider {...props}>
    <PageContext.Provider value={props.pageContext}>
      <ParallaxProvider>
        <AppHelmet {...props} />
        <CookieConsent />
        {props.pageContext.originalPath === '/' ? (
          <main>{children}</main>
        ) : (
          <BaseLayout>{children}</BaseLayout>
        )}
      </ParallaxProvider>
    </PageContext.Provider>
  </AppIntlProvider>
);

export default Layout;
