// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import MaterialUILayout from 'material-ui-layout';
import { Location } from '@reach/router';

import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import HomeButton from '../components/HomeButton';

const offsetV = 400;

const styles = (theme: Theme) => ({
  appBar: {
    top: -offsetV,
    left: 0,
    maxWidth: '80px',
    height: `calc(100vh + ${offsetV * 2}px)`,
    paddingRight: '0 !important',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '50px',
    },
  },
  appBarContent: {
    padding: `${offsetV + theme.spacing.unit * 2}px 0px`,
  },
  mainFixedAppBar: {
    marginTop: 0,
  },
  drawerPaper: {
    width: '35vw',
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    paddingLeft: 80,
    [theme.breakpoints.down('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      paddingLeft: 50,
      width: '100%',
    },
  },
});

const menuPagesRegExps = [/\/restaurant\/.*/];

const links = [
  {
    key: 'menu',
    label: 'Menu',
    to: 'menu',
  },
  {
    key: 'schedule',
    label: 'Hours',
    to: 'schedule',
  },
  {
    key: 'location',
    label: 'Location',
    to: 'location',
  },
  {
    key: 'recipes',
    label: 'Recipes',
    to: 'recipes',
  },
  {
    key: 'about',
    label: 'About us',
    to: 'about',
  },
  {
    key: 'careers',
    label: 'Careers',
    to: 'careers',
  },
];

export type Props = {
  children: React.Node,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BaseLayout = ({
  children,
  classes: { appBarContent: classes$appBarContent, ...classes },
}: Props) => (
  <Location>
    {({ location }) => (
      <MaterialUILayout
        classes={classes}
        mainGrow={false}
        appBarContent={
          <AppBar
            className={classes$appBarContent}
            goBack={
              !menuPagesRegExps.some(regExp => regExp.exec(location.pathname))
            }
          />
        }
        leftDrawerContent={<Drawer links={links} />}
        leftDrawerProps={{
          ModalProps: { BackdropProps: { invisible: true } },
        }}
      >
        <HomeButton />
        {children}
      </MaterialUILayout>
    )}
  </Location>
);

export default withStyles(styles)(BaseLayout);
