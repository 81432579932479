// @flow
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as GatsbyLink } from 'gatsby';
import { type IntlShape } from 'react-intl';
import classnames from 'classnames';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import type { HOC } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';

import withLocalizePath, { type LocalizePath } from '../hocs/withLocalizePath';
import withPageContext from '../../page-context/hocs/withPageContext';

export const styles = () => ({
  root: {
    textDecoration: 'none',
  },
});

export type Props = {
  ...$Exact<React.ElementConfig<typeof GatsbyLink>>,
  intl: IntlShape,
  localizePath: LocalizePath,
  localized?: boolean,
  className?: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
};

const Link = ({
  to,
  intl,
  localizePath,
  localized,
  classes,
  className,
  pageContext: unusedPageContext,
  ...props
}: Props) => (
  <GatsbyLink
    {...props}
    className={classnames(className, classes.root)}
    to={localized ? to : localizePath(to)}
  />
);

Link.defaultProps = {
  className: undefined,
  localized: false,
};

const enhancer: HOC<
  Props,
  {
    ...$Diff<Props, { classes: any, to: any }>,
    classes?: $ElementType<Props, 'classes'>,
    to: $NonMaybeType<$ElementType<Props, 'to'>>,
  },
> = compose(
  withLocalizePath(),
  withPageContext(),
  withPropsOnChange(['to', 'pageContext'], ({ to, pageContext, localized }) => {
    const prefixCurrentPath = to && /^[#?&]/.test(to);
    return {
      // IMPORTANT: make sure to unset the localized flag when we are prefixing
      // the current path
      localized: !prefixCurrentPath && localized,
      to: prefixCurrentPath ? `${pageContext.originalPath}${to}` : to,
    };
  }),
  withStyles(styles),
);

export default enhancer(Link);
