// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-templates-restaurant-page-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages-templates/RestaurantPage.jsx" /* webpackChunkName: "component---src-pages-templates-restaurant-page-jsx" */),
  "component---src-pages-templates-blog-entry-page-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages-templates/BlogEntryPage.jsx" /* webpackChunkName: "component---src-pages-templates-blog-entry-page-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archive-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-cookies-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/dominichacking/Development/PERS/26-grains/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/dominichacking/Development/PERS/26-grains/.cache/data.json")

