// @flow
import { injectIntl, type IntlShape } from 'react-intl';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import type { HOC } from 'recompose';

import i18n from '../i18n';

export type LocalizePath = string => string;

export type WithLocalizePathHOC<E: {}> = HOC<
  { ...$Exact<E>, intl: IntlShape, localizePath: LocalizePath },
  E,
>;

export default function withLocalizePath<
  Enhanced: {},
>(): WithLocalizePathHOC<Enhanced> {
  return compose(
    injectIntl,
    withHandlers({
      localizePath: ({ intl: { locale } }): LocalizePath => path =>
        i18n.localizePath(locale, path),
    }),
  );
}
