// @flow

import * as React from 'react';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { IntlProvider } from 'react-intl';
// import moment from 'moment';

import '../../../../config/i18n';
import '../../../../config/i18n/localeData';
import * as messages from '../../../../config/i18n/messages';
import type { GatsbyNode$LocalizedPage } from '../types';

export type Props = {
  pageContext: $ElementType<GatsbyNode$LocalizedPage<>, 'context'>,
  children: React.Node,
};

const AppIntlProvider = ({
  children,
  pageContext: { locale },
}: Props): React.Node => (
  <IntlProvider locale={locale.id} messages={messages[locale.id]}>
    {children}
  </IntlProvider>
);

const enhancer = withPropsOnChange<$Shape<{||}>, Props>(
  (props, nextProps) =>
    props.pageContext.locale !== nextProps.pageContext.locale,
  // eslint-disable-next-line no-unused-vars
  ({ pageContext: { locale } }) => {
    // moment.locale(locale.id);
    return {};
  },
);

export default enhancer(AppIntlProvider);
