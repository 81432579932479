// @flow
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import MUICookieConsent from 'material-ui-cookie-consent';
import RichText from '../../prismic/components/RichText';
import Link from '../../i18n/components/Link';
import type { Query } from '../../../schema.flow';

const CookieConsent = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          prismicCookieConsent {
            id
            data {
              description {
                text
                html
              }
              read_more_button {
                text
              }
              accept_button {
                text
              }
            }
          }
        }
      `}
      render={(query: Query) => (
        <MUICookieConsent
          cookieName="26Grains-cookie-consent"
          snackbarAnchor={{ horizontal: 'right', vertical: 'bottom' }}
          message={
            RichText.hasValue(query.prismicCookieConsent?.data?.description) ? (
              <Typography color="primary" variant="body1">
                <RichText {...query.prismicCookieConsent?.data?.description} />
              </Typography>
            ) : null
          }
          actions={
            query.prismicCookieConsent?.data?.read_more_button?.text ? (
              <Button component={Link} to="/cookies">
                <Typography
                  color="primary"
                  variant="button"
                  style={{ letterSpacing: 0.01 }}
                >
                  {query.prismicCookieConsent?.data?.read_more_button?.text}
                </Typography>
              </Button>
            ) : null
          }
          acceptButtonLabel={
            query.prismicCookieConsent?.data?.accept_button?.text ? (
              <Typography color="primary" variant="button">
                {query.prismicCookieConsent?.data?.accept_button?.text}
              </Typography>
            ) : null
          }
        />
      )}
    />
  );
};

export default CookieConsent;
