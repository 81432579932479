// @flow
import * as React from 'react';
import type { HOC } from 'recompose';

import PageContext from '../index';
import type { Page$Context } from '../types';

export type LocalizePath = string => string;

export type WithPageContextHOC<E: {}> = HOC<
  { ...$Exact<E>, pageContext: Page$Context },
  E,
>;

export default function withPageContext<
  Enhanced: {},
>(): WithPageContextHOC<Enhanced> {
  return Component => {
    const WithPageContext = (props: Enhanced) => (
      <PageContext.Consumer>
        {(pageContext: Page$Context | void) => (
          <Component {...props} pageContext={pageContext} />
        )}
      </PageContext.Consumer>
    );
    return WithPageContext;
  };
}
