// @flow
import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import castArray from 'lodash/castArray';
import { injectIntl, type IntlShape } from 'react-intl';

import type { Query } from '../../../schema.flow';

export type Props = {
  intl: IntlShape,
  title?: string,
  description?: string,
  keywords?: Array<string>,
  children?: React.Node,
};

const AppHelmet = ({
  intl,
  title,
  description,
  keywords,
  children,
  ...props
}: Props) => (
  <StaticQuery
    query={graphql`
      query AppHelmetQuery {
        site {
          siteMetadata {
            siteName
            title
            description
            author
          }
        }
      }
    `}
    render={(data: Query) => {
      const title$short = title || data.site?.siteMetadata?.title || '';
      const title$long = `${title ? `${title} | ` : ''}${data.site?.siteMetadata
        ?.title || ''}`;
      return (
        <Helmet {...props}>
          <html lang={intl.locale} />
          <meta name="og:type" content="website" />
          <meta
            name="og:site_name"
            content={data.site?.siteMetadata?.siteName || title$short}
          />
          <title>{title$long}</title>
          <meta name="og:title" content={title$short} />
          {description ? (
            <meta name="description" content={description} />
          ) : null}
          {description ? (
            <meta name="og:description" content={description} />
          ) : null}
          {keywords && keywords.length ? (
            <meta name="keywords" content={castArray(keywords).join(',')} />
          ) : null}
          {children}
        </Helmet>
      );
    }}
  />
);

AppHelmet.defaultProps = {
  title: undefined,
  description: undefined,
  keywords: [],
  children: null,
};

export default injectIntl(AppHelmet);
