// @flow

import * as React from 'react';

import hasRichTextFieldValue from '../helpers/hasRichTextFieldValue';
import type { RichTextField } from '../types';

export type Props = {
  component?: React.ElementType,
  ...$Exact<RichTextField>,
};

const RichText = ({
  component: Component,
  html,
  raw,
  text,
  ...props
}: Props): React.Node =>
  Component && hasRichTextFieldValue({ html, text }) ? (
    <Component {...props} dangerouslySetInnerHTML={{ __html: html }} />
  ) : null;

RichText.defaultProps = {
  component: 'div',
};

RichText.hasValue = hasRichTextFieldValue;

export default RichText;
