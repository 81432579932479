/**
 * GatsbyStarterConfig: locale data
 * 
 * Load all locale data that is required on the client. That is, data
 * needed by libraries to work.
 */

import { addLocaleData } from 'react-intl';

import en from 'react-intl/locale-data/en';
// import 'moment/locale/en';

addLocaleData([...en]);
