// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';

import DrawerItem from './DrawerItem';

const styles = (theme: Theme) => ({
  wrapper: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit}px`,
    },
  },
});

export type Props = {
  links: Array<{
    ...$Exact<React.ElementConfig<typeof DrawerItem>>,
    key: string,
  }>,
  closeDrawer?: () => void,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

export type State = {
  open: boolean,
};

const Drawer = ({ classes, links, closeDrawer }: Props) => (
    <div className={classes.wrapper}>
      {links.map(({ key, to, label }) => (
        <DrawerItem
          key={key}
          to={to}
          label={label}
          onClick={closeDrawer}
        />
      ))}
    </div>
);

Drawer.defaultProps = {
  closeDrawer: undefined,
};

export default withStyles(styles)(Drawer);
