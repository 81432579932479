// @flow

import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import Image from 'gatsby-image';

import Link from '../plugins/i18n/components/Link';
import type { Query } from '../schema.flow';

const styles = () => ({
  root: {
    width: '100%',
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1,
  },
  logo: {
    float: 'right',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles>>]: string },
};

const HomeButton = ({ classes }: Props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "26GSmLogo.png" }) {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(query: { logo: $ElementType<Query, 'file'> }) => {
      return (
        <div className={classes.root}>
          <Link to="/">
            <Image {...query?.logo?.childImageSharp} className={classes.logo} />
          </Link>
        </div>
      );
    }}
  />
);

export default withStyles(styles)(HomeButton);
