// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Image from 'gatsby-image';
import classnames from 'classnames';
import map from 'lodash/map';

import type { Query } from '../../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  iconBase: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '25px',
    },
  },
  verticalText: {
    pointerEvents: 'none', // maybe no needed
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate(180deg)',
    textDecoration: 'none',
  },
  adress: {
    flex: 1,
    paddingTop: '12px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '35px',
    },
  },
});

export type Props = {
  goBack: boolean,
  toggleLeftDrawer?: () => void,
  className?: string,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

class AppBar extends React.Component<Props> {
  static defaultProps = {
    className: undefined,
    toggleLeftDrawer: undefined,
  };

  handleIconClick = () => {
    const { toggleLeftDrawer, goBack } = this.props;
    if (goBack) {
      if (window && window.history) {
        window.history.back();
      }
    } else if (toggleLeftDrawer) {
      // work arround because MUILayout don't have typings
      toggleLeftDrawer();
    }
  };

  render() {
    const { classes, className, goBack } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            menuIcon: file(relativePath: { eq: "DropMenu.png" }) {
              childImageSharp {
                fluid(maxWidth: 40) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            arrowIcon: file(relativePath: { eq: "BackArrow.png" }) {
              childImageSharp {
                fluid(maxWidth: 40) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            allPrismicRestaurant(
              sort: { fields: first_publication_date, order: ASC }
            ) {
              edges {
                node {
                  id
                  data {
                    restaurant_adress {
                      text
                    }
                  }
                }
              }
            }
          }
        `}
        render={(query: {
          menuIcon: $ElementType<Query, 'file'>,
          arrowIcon: $ElementType<Query, 'file'>,
          allPrismicRestaurant: $ElementType<Query, 'allPrismicRestaurant'>,
        }) => {
          return (
            <Toolbar className={classnames(className, classes.root)}>
              <IconButton
                disableRipple
                classes={{ root: classes.iconBase }}
                onClick={this.handleIconClick}
                {...(goBack
                  ? {
                      'aria-label': 'Back Arrow',
                    }
                  : {
                      'aria-label': 'Open menu',
                    })}
              >
                <Image
                  {...(goBack
                    ? query?.arrowIcon?.childImageSharp
                    : query?.menuIcon?.childImageSharp)}
                  className={classes.icon}
                />
              </IconButton>
              <div className={classnames(classes.adress, classes.verticalText)}>
                {map(query.allPrismicRestaurant?.edges, ({ node }) => (
                  <div key={node?.id}>
                    <Typography variant="body2">
                      {node?.data?.restaurant_adress?.text}
                    </Typography>
                  </div>
                ))}
              </div>
            </Toolbar>
          );
        }}
      />
    );
  }
}

export default withStyles(styles)(AppBar);
