// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as ScrollLink } from 'react-scroll';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  wrapper: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  text: {
    width: 'auto',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    opacity: 0.7,
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 1}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
});

export type Props = {
  label: string,
  to: string,
  onClick?: () => void,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const DrawerItem = ({ classes, onClick, to, label }: Props) => (
  <Button
    to={to}
    onClick={onClick}
    disableRipple
    duration={1000}
    offset={-30}
    smooth
    component={ScrollLink}
    classes={{ root: classes.root }}
  >
    <ListItem aria-label="Menu links" className={classes.wrapper}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{ variant: 'h4', className: classes.text }}
      />
    </ListItem>
  </Button>
);

DrawerItem.defaultProps = {
  onClick: undefined,
};

export default withStyles(styles)(DrawerItem);
